export default `Acceptance of the Website Terms and Conditions of Use
These website terms and conditions of use for www.iwaretravel.ca, constitute a legal agreement and are entered into by and between you and iWare Travel Inc. (the "Company," "we," "us," and "our"). The following terms and conditions, together with any documents and/or additional terms they expressly incorporate by reference (collectively, these "Terms and Conditions"), govern your access to and use, including any content, functionality, and services offered on or through www.iwaretravel.ca (the "Website").
BY USING THE WEBSITE, YOU ACCEPT AND AGREE TO BE BOUND AND COMPLY WITH THESE TERMS AND CONDITIONS. IF YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS, YOU MUST NOT ACCESS OR USE THE WEBSITE.
By using this Website, you represent and warrant that you are the legal age of majority under applicable law to form a binding contract with the Company and meet all of the foregoing eligibility requirements. If you do not meet all of these requirements, you must not access or use the Website.
Modifications to the Terms and Conditions and to the Website
We reserve the right in our sole discretion to revise and update these terms and conditions from time to time. Any and all such modifications are effective immediately upon posting and apply to all access to and continued use of the Website. You hereby agree to periodically review the terms and conditions in order to be aware of any such modifications and your continued use shall be your acceptance of these.
The information and material on this Website, and the Website, may be changed, withdrawn or terminated at any time in our sole discretion without notice. We will not be liable if, for any reason, all or any part of the Website is restricted to users or unavailable at any time or for any period.
Use of the Website
The Company is an insurance brokerage licensed to operate in Canada, and insurance products and services are available only in those jurisdictions where they may be legally offered.

We do not review or control, and are not responsible for, any websites linked from or to the Website, the content of those websites, the privacy practices of those websites, the third parties named therein, or their products or services. Linking to any other website is at your sole risk and the Company will not be responsible or liable for any damages in connection with such linking. In addition, we does not endorse or approve of any websites linked from or linking to the Website.

The Company does not guarantee and makes no representations as to the accuracy, quality, validity or authenticity of any rates, terms or information related to financial or insurance products.

Receiving an insurance quote online
By using this Website, you are able to obtain an insurance quote online. You must disclose all material facts that may affect the way your premium is determined and the information you provide to us must be correct and accurate, otherwise, we may not be able to provide you with an accurate quote.
You agree that clicking on certain buttons stating "yes", "next", "confirm", "continue" and any other similar descriptions will constitute your signature and confirm your intention to apply for, purchase, or modify an insurance quote. You agree to be bound by these Terms of Use and to pay the premium and any applicable taxes. You further agree that the Company shall not be responsible or liable, directly or indirectly, in any way for any loss or damage of any kind incurred as a result of, or in connection with your use of, or reliance on, any third party insurance information.

Your Use of the Website and Account Set-Up and Security
The security of your personal information is very important to us. We use physical, electronic, and administrative measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure.
The safety and security of your information also depends on you. Users are responsible for obtaining their own access to the Website. Users are required to ensure that all persons who access the Website through a user's internet connection are aware of these Terms and Conditions and comply with them. The Website, including content or areas of the Website, may require user registration. It is a condition of your use of the Website that all the information you provide on the Website is correct, current, and complete.
Unfortunately, the transmission of information via the Internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Website. We are not liable for any direct and indirect damages which may arise if you do not take the necessary steps to enhance your security when using the internet for your transactions online.
Your provision of registration information and any submissions you make to the Website through any functionality such as quotes, applications, e-mail, and profiles (collectively, "Interactive Functions") constitutes your consent to all actions we take with respect to such information consistent with the section titled "User Privacy".
Any username, password, or any other piece of information chosen by you, or provided to you as part of our security procedures, must be treated as confidential, and you must not disclose it to any other person or entity. You must exercise caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information. You understand and agree that should you be provided an account, your account is personal to you and you agree not to provide any other person with access to this Website or portions of it using your username, password, or other security information. You agree to notify us immediately of any unauthorized access to or use of your username or password or any other breach of security. You also agree to ensure that you logout from your account at the end of each session. You are responsible for any password misuse or any unauthorized access.
We reserve the right at any time and from time to time, to disable or terminate your account, any username, password, or other identifier, whether chosen by you or provided by us, in our sole discretion for any or no reason, including any violation of any provision of these Terms and Conditions.
You are prohibited from attempting to circumvent and from violating the security of this Website, including, without limitation: (a) accessing content and data that is not intended for you; (b) attempting to breach or breaching the security and/or authentication measures which are not authorized; (c) restricting, disrupting or disabling service to users, hosts, servers or networks; (d) illicitly reproducing TCP/IP packet header; (e) disrupting network services and otherwise disrupting Website owner's ability to monitor the Website; (f) using any robot, spider, or other automatic device, process, or means to access the Website for any purpose, including monitoring or copying any of the material on the Website; (g) introducing any viruses, trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful; (h) attacking the Website via a denial-of-service attack, distributed denial-of-service attack, flooding, mailbombing or crashing; and (i) otherwise attempting to interfere with the proper working of the Website.
Customer Account
You are not obligated to register with us in order to access and use the Website. However, certain services of the Website are available only to users who have registered with us or certain other persons affiliated with us, who have been granted accounts (“Customer Accounts”) with usernames and passwords. You represent and warrant that all required registration information you submit regarding your Customer Account is truthful and accurate, and that you will maintain the accuracy of such information (including email). You may delete your Customer Account at any time, for any reason, by following the instructions on the Website. Company may suspend or terminate your Customer Account in accordance with these Terms of Use.
You are responsible for maintaining the confidentiality of your Customer Account login information and are fully responsible for all activities that occur under your Account. You agree to immediately notify Company of any unauthorized use, or suspected unauthorized use of your Customer Account or any other breach of security. The Company is not liable for any loss or damage arising from your failure to comply with the above requirements regarding your Customer Account.
Intellectual Property Rights and Ownership
You understand and agree that the Website and its entire contents, features, and functionality, including, but not limited to, all information, software, code, text, displays, graphics, photographs, video, audio, design, presentation, selection, and arrangement, are owned by the Company, its licensors, or other providers of such material and are protected in all forms by intellectual property laws including without limitation, copyright, trademark, patent, trade secret, and any other proprietary rights.
The Company name, the Company logo, and all related names, logos, products and service names, designs, images and slogans belong to the Company. You must not use such marks without the prior written permission of the Company. Other names, logos, product and service names, designs, images and slogans mentioned, or which appear on this Website are the trademarks of their respective owners. Use of any such property, except as expressly authorized, shall constitute an infringement or violation of the rights of the property owner and may be a violation of federal or other laws and could subject the infringer to legal action.
You may only use the Website for your personal use. You shall not directly or indirectly reproduce, compile for an internal database, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Website, in any form or medium whatsoever except:
(a)	your computer and browser may temporarily store or cache copies of materials being accessed and viewed;
(b)	a reasonable number of copies for personal use only may be printed keeping any proprietary notices thereon, which may only be used for non-commercial and lawful personal use and not for further reproduction, publication, or distribution of any kind on any medium whatsoever;
(c)	one single user copy may be downloaded with any proprietary notices intact, for your own personal, non-commercial use, conditional on your agreement to be bound by our end user licence agreement for such downloads; and
(d)	in the event social media platforms are linked to certain content on our Website, you may take such actions as our Website and such third-party social media platforms permit.
Users are not permitted to modify copies of any materials from this Website nor delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from this site. You must not access or use for any commercial purposes any part of the Website or any services or materials available through the Website.
If you print off, copy or download any part of our Website in breach of these Terms and Conditions, your right to use the Website will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. You have no right, title, or interest in or to the Website or to any content on the Website, and all rights not expressly granted are reserved by the Company. Any use of the Website not expressly permitted by these Terms and Conditions is a breach of these Terms and Conditions and may infringe or violate copyright, trademark, and other intellectual property or other proprietary laws.
As a condition of your access and use, you agree that you may use the Website only for lawful purposes and in accordance with these Terms and Conditions.
User Submissions: Grant of Licence
The Website may contain Interactive Functions allowing User Submissions on or through the Website.
None of the User Submissions you submit to the Website will be subject to any confidentiality by the Company. By providing any User Submission to the Website, you grant us and our affiliates and service providers, and each of their and our respective licensees, successors, and assigns the right to a world-wide, royalty free, perpetual, irrevocable, non-exclusive licence to use, reproduce, modify, perform, display, distribute, and otherwise disclose to third parties any such material for any purpose and according to your account settings and/or incorporate such material into any form, medium or technology throughout the world without compensation to you. You further waive any moral rights or other rights of authorship as a condition of submitting any User Submission.
By submitting the User Submissions, you declare and warrant that you own or have the necessary rights to submit the User Submissions and have the right to grant the licence hereof to us and our affiliates and service providers, and each of their and our respective licensees, successors, and assigns to the User Submissions and comply with these Terms and Conditions. You represent and warrant that all User Submissions comply with applicable laws and regulations and the User Submissions and Site Content Standards set out in these Terms and Conditions.
You understand and agree that you, not the Company, are fully responsible for any User Submissions you submit or contribute, and you are fully responsible and legally liable, including to any third party, for such content and its accuracy. We are not responsible or legally liable to any third party for the content or accuracy of any User Submissions submitted by you or any other user of the Website.
No Reliance
Although we make reasonable efforts to update the information on our Website, we make no representations, warranties or guarantees, whether express or implied, that the content on our Website is accurate, complete or up to date. Your use of the Website is at your own risk and neither the Company nor its directors, officers, employees, agents, service providers, contractors, licensors, licensees, suppliers or successors have any responsibility or liability whatsoever for your use of this Website.
This Website may include content provided by third parties, including from other users and third-party licensors. All statements and/or opinions expressed in any such third-party content, other than the content provided by the Company, are solely the opinions and the responsibility of the person or entity providing those materials. Such materials do not necessarily reflect the opinion of the Company. The Company nor its directors, officers, employees, agents, service providers, contractors, licensors, licensees, suppliers or successors have any responsibility or liability whatsoever to you, or any third party, for the content or accuracy of any third-party materials.
User Privacy
By submitting your personal information and using our Website, you consent to the collection, use, reproduction, hosting, transmission and disclosure of any such user content submissions in compliance with laws applicable in the Province of Alberta, and all federal laws applicable therein, as we deem necessary for use of the Website and provision of services.
By using this Website you are consenting to the use of cookies which allow a server to recall previous requests or registration and/or IP addresses to analyze website use patterns. You can set your browser to notify you before you receive a cookie, giving you the chance to decide whether to accept it. You can also set your browser to turn off cookies. If you do, however, some areas of the Website may not function adequately.
We may use your email address to verify your identity, protect against fraud, and contact you. In addition, we may use it to send you updates about your account, new products and services, and Website updates.
Personal information you submit to the Website may be employed for the purposes of maintaining the integrity of the Site and the content thereon, contacting you when necessary, providing services as requested, and improving the quality of service provided by the Site.
The Company reserves the right to disclose your personally identifiable information as required by law and when we believe that disclosure is necessary to protect our rights and/or comply with a judicial proceeding, court order, or legal process served on our Website; or to protect us and our successors and assigns, and any of our and their respective officers, directors, employees, members, agents, and representatives. While we make every effort to ensure the integrity and security of our network and systems, we cannot guarantee that our security measures will prevent third-party “hackers” from illegally obtaining this information.
When we disclose your personal information to third parties, we require them to protect and handle your personal information in a manner consistent with our privacy practices and all applicable laws. These third parties may at times process and store your information outside of Canada. If your personal information is processed or stored in a foreign country, it will be subject to that country’s laws and may be disclosed in accordance with those laws.

You may withdraw your consent to the collection, use and disclosure of your personal information, subject to certain limitations. However, if you do so, we may not be able to continue to provide you with our insurance products and services, or our best rate on your insurance policy. You may also withdraw your consent to the collection, use and disclosure of your personal information for marketing practices. If you want to withdraw your consent, please contact us.

Third Party Websites
For your convenience, this Website may provide links or pointers to third-party sites. We make no representations about any other websites that may be accessed from this Website. If you choose to access any such sites, you do so at your own risk. We have no control over the contents of any such third-party sites and accept no responsibility for such sites or for any loss or damage that may arise from your use of them. You are subject to any terms and conditions of such third-party sites.
Such links to third-party sites from the Website may include links to certain social media features that enable you to link or transmit on your own or using certain third-party websites, certain content from this Website. You may only use these features when they are provided by us and solely with respect to the content identified.
You may link to our homepage, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it. You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists. Our Website must not be framed on any other site, nor may you create a link to any part of our Website other than the homepage. We reserve the right to withdraw linking permission without notice. The website in which you are linking must comply in all respects with the Conditions of Use and User Submissions and Site Content Standards. You agree to cooperate with us in causing any unauthorized framing or linking to immediately stop.
Geographic Restrictions
The owner of the Website is based in the Province of Alberta in Canada. This Website is not intended for use in any jurisdiction where its use is not permitted.
Disclaimer of Warranties
YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
NEITHER THE COMPANY NOR ITS PARENT, SUBSIDIARIES, AFFILIATES OR THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS OR SUCCESSORS MAKE ANY WARRANTY, REPRESENTATION OR ENDORSEMENT WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, SUITABILITY, ACCURACY, CURRENCY OR AVAILABILITY OF THE WEBSITE OR ITS CONTENTS. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ITS PARENT, SUBSIDIARIES, AFFILIATES OR THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS OR SUCCESSORS REPRESENT OR WARRANT THAT THE WEBSITE, ITS CONTENT, OR ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR WEBSITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
WE CANNOT AND DO NOT GUARANTEE OR WARRANT THAT FILES OR DATA AVAILABLE FOR DOWNLOADING FROM THE INTERNET OR THE WEBSITE WILL BE FREE OF VIRUSES OR OTHER DESTRUCTIVE CODE. YOU ARE SOLELY AND ENTIRELY RESPONSIBLE FOR YOUR USE OF THE WEBSITE AND YOUR COMPUTER, INTERNET AND DATA SECURITY. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY DENIAL-OF-SERVICE ATTACK, DISTRIBUTED DENIAL-OF-SERVICE ATTACK, OVERLOADING, FLOODING, MAILBOMBING OR CRASHING, VIRUSES, TROJAN HORSES, WORMS, LOGIC BOMBS, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.
Limitation on Liability
EXCEPT WHERE SUCH EXCLUSIONS ARE PROHIBITED BY LAW, UNDER NO CIRCUMSTANCE WILL THE COMPANY NOR ITS PARENT, SUBSIDIARIES, AFFILIATES OR THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS BE LIABLE FOR NEGLIGENCE, GROSS NEGLIGENCE, NEGLIGENT MISREPRESENTATION, FUNDAMENTAL BREACH, DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING, BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, BREACH OF PRIVACY OR OTHERWISE, EVEN IF THE PARTY WAS ALLEGEDLY ADVISED OR HAD REASON TO KNOW, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, OR RELIANCE ON, THE WEBSITE, ANY LINKED WEBSITES OR SUCH OTHER THIRD-PARTY WEBSITES, NOR ANY WEBSITE CONTENT, MATERIALS, POSTING OR INFORMATION THEREON EVEN IF THE PARTY WAS ALLEGEDLY ADVISED OR HAD REASON TO KNOW.
Indemnification
To the maximum extent permitted by applicable law, you agree to defend, indemnify, and hold harmless the Company and its respective directors, officers, employees, agents, service providers, contractors, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your breach of these Terms and Conditions or your use of the Website, including, but not limited to, your User Submissions, third-party sites, any use of the Website's content, services, and products other than as expressly authorized in these Terms and Conditions.
Governing Law and Jurisdiction
The Website and these Terms and Conditions will be governed by and construed in accordance with the laws of the Province of Alberta and the federal laws of Canada applicable therein, without giving effect to any choice or conflict of law provision, principle or rule (whether of the laws of the Province of Alberta or any other jurisdiction) and notwithstanding your domicile, residence or physical location.
Any action or proceeding arising out of or relating to this Website and under these Terms and Conditions will be instituted in the courts of the province of the Province of Alberta and/or the Federal Court of Canada, and each party irrevocably submits to the exclusive jurisdiction of such courts in any such action or proceeding. You waive any and all objections to the exercise of jurisdiction over you by such courts and to the venue of such courts.
Waiver
No failure to exercise, or delay in exercising, any right, remedy, power or privilege arising from these Terms and Conditions operates, or may be construed, as a waiver thereof. No single or partial exercise of any right, remedy, power or privilege hereunder precludes any other or further exercise thereof or the exercise of any other right, remedy, power or privilege.
Severability
If any term or provision of these Terms and Conditions is invalid, illegal or unenforceable in any jurisdiction, such invalidity, illegality or unenforceability shall not affect any other term or provision of these Terms and Conditions or invalidate or render unenforceable such term or provision in any other jurisdiction.
Entire Agreement
The Terms and Conditions constitute the sole and entire agreement between you and iWare Travel Inc. regarding the Website and supersedes all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, regarding such subject matter.
Reporting and Contact
This website is operated by iWare Travel Inc.
Should you become aware of misuse of the website including libelous or defamatory conduct, you must report it to the Company at lancy@iwaretravel.ca.
All other feedback, comments, requests for technical support, and other communications relating to the Website should be directed to lancy@iwaretravel.ca.`
